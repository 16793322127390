import React from 'react';
import cc from 'classcat';
import {useRouter} from 'next/router';

import {useStore} from '../../../hooks';

import logoAQF from '../../../images/logos/logo-AQF.png';
import logoAQFwebp from '../../../images/logos/webp/logo-AQF.png.webp';
import logoNRT from '../../../images/logos/logo-NRT.png';
import logoNRTwebp from '../../../images/logos/webp/logo-NRT.png.webp';
import logoTAC from '../../../images/logos/logo-TAC.png';
import logoTACwebp from '../../../images/logos/webp/logo-TAC.png.webp';
import logoJSWA from '../../../images/logos/logo-JSWA.png';
import logoJSWAwebp from '../../../images/logos/webp/logo-JSWA.png.webp';
import logoLFLS from '../../../images/logos/logo-LFLS.png';
import logoLFLSwebp from '../../../images/logos/webp/logo-LFLS.png.webp';

import FooterMenu from './FooterMenu';

import styles from './styles.module.scss';

const copyText = <p>©  {new Date().getFullYear()} Amana Living</p>;

const copyTextMobile = <span>©  {new Date().getFullYear()} Amana Living</span>;

interface Props {
    isHubPage: boolean;
}

const Footer = ({isHubPage}: Props) => {
    const {
        site,
        siteName,
        invertedLogo,
        instagramLink,
        facebookLink,
        linkedinLink,
        youtubeLink,
        careersLink,
    } = useStore('globals');
    const router = useRouter();

    const trainingSiteLogos = () => {
        if (site !== 'training' || router.pathname.startsWith('/courses/')) {
            return null;
        }

        return (
            <div className={styles.topLogosContainer}>
                <div className={styles.topLogos}>
                    <a href="https://www.aqf.edu.au/" title="Australian Qualifications Framework" target="_blank"
                       rel="noopener nofollow">
                        <picture>
                            <source
                                sizes="280px"
                                src={logoAQFwebp}
                                type="image/webp"
                            />
                            <img
                                src={logoAQF}
                                sizes="280px"
                                alt="Australian Qualifications Framework"
                                width="280px"
                                height="147px"
                            />
                        </picture>
                    </a>

                    <picture>
                        <source
                            sizes="280px"
                            src={logoNRTwebp}
                            type="image/webp"
                        />
                        <img
                            src={logoNRT}
                            sizes="280px"
                            alt="Nationally Recognised Training"
                            width="280px"
                            height="229px"
                        />
                    </picture>

                    <a href="https://www.wa.gov.au/organisation/training-accreditation-council"
                       title="Training Accreditation Council" target="_blank" rel="noopener nofollow">
                        <picture>
                            <source
                                sizes="280px"
                                src={logoTACwebp}
                                type="image/webp"
                            />
                            <img
                                src={logoTAC}
                                sizes="280px"
                                alt="Training Accreditation Council"
                                width="280px"
                                height="127px"
                            />
                        </picture>
                    </a>

                    <a href="https://www.jobsandskills.wa.gov.au/" title="Jobs & Skills WA" target="_blank"
                       rel="noopener nofollow">
                        <picture>
                            <source
                                sizes="280px"
                                src={logoJSWAwebp}
                                type="image/webp"
                            />
                            <img
                                src={logoJSWA}
                                sizes="280px"
                                alt="Jobs & Skills WA"
                                width="280px"
                                height="108px"
                            />
                        </picture>
                    </a>

                    <picture>
                        <source
                            sizes="280px"
                            src={logoLFLSwebp}
                            type="image/webp"
                        />
                        <img
                            src={logoLFLS}
                            sizes="280px"
                            alt="Lower Fees Local Skills"
                            width="280px"
                            height="102px"
                        />
                    </picture>
                </div>
            </div>
        );
    };

    return (
        <footer
            className={cc({
                [styles.footer]: true,
                [styles.notHubPage]: !isHubPage,
            })}
        >
            {trainingSiteLogos()}

            <div className="container">
                <div className={styles.inner}>
                    {/*<div className={styles.callUs}>*/}
                    {/*    <PhoneLink text="Call" type="buttonWhite"/>*/}
                    {/*</div>*/}
                    <div className={styles.logos}>
                        <a href={router?.asPath === '/jobs' ? careersLink : '/'} className={styles.mainLogo}>
                            <img
                                src={'/_misc/logo-w.svg'}
                                alt="Amana Living" width="150" height="114"/>
                        </a>
                        {/*<a href="/about/clinical-excellence">*/}
                        {/*    <ClinicalIcon width="108" height="33"/>*/}
                        {/*</a>*/}
                        {site === 'training' && <p>RTO Code: 0609</p>}
                        {copyText}
                        <div className={styles.legalLinks}>
                            <a href="/privacy">Privacy</a>
                            <a href="/disclaimer">Disclaimer</a>
                            <a href="/terms-conditions">Terms & Conditions</a>
                        </div>
                    </div>
                    <FooterMenu/>
                    <div className={styles.logosMobile}>
                        <a href="/">{invertedLogo && <img src={invertedLogo} alt={siteName} height="96"/>}</a>
                        {/*<a href="/about/clinical-excellence">*/}
                        {/*    <ClinicalIcon width="108" height="33"/>*/}
                        {/*</a>*/}
                    </div>
                    <div className={styles.socials}>
                        <p className={styles.byline}>
                            Professionally trained. <span>Naturally kind.</span>
                        </p>
                        <p className={styles.socialIntro}>Follow Us</p>
                        <div>
                            {copyTextMobile}
                            {facebookLink && (
                                <a href={facebookLink} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                                    <img src={'/_misc/socials/facebook.svg'} alt={'facebook'} />
                                </a>
                            )}
                            {linkedinLink && (
                                <a href={linkedinLink} target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                                    <img src={'/_misc/socials/linkedin.svg'} alt={'linked in'} />
                                </a>
                            )}
                            {instagramLink && (
                                <a href={instagramLink} target="_blank" rel="noopener noreferrer"
                                   aria-label="Instagram">
                                    <img src={'/_misc/socials/instagram.svg'} alt={'instagram'} />
                                </a>
                            )}
                            {youtubeLink && (
                                <a href={youtubeLink} target="_blank" rel="noopener noreferrer" aria-label="YouTube">
                                    <img src={'/_misc/socials/youtube.svg'} alt={'youtube'} />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.svgBackgroundContainer}>
                <img className={styles.svgBackground} src={'/_misc/footer-bg.svg'} alt={''} />
            </div>
        </footer>
    );
};

export default Footer;
