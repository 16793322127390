import React from 'react';

import { useSiteQuery } from '../../../../hooks';
import { renderCategoryLink } from '../../../../lib/ContentRenderer';

import query from './footerMenu.graphql';

import styles from './styles.module.scss';

const FooterMenu = () => {
  const [{ data }] = useSiteQuery<FooterMenuQuery>({ query });
  const footerLinks = data?.footerLinks;

  if (footerLinks) {
    const parents = footerLinks.filter((item) => item?.parent === null);

    return (
      <div className={styles.footerMenu}>
        {parents.map(
          (p) =>
            p && (
              <ul key={p.title}>
                <li className={styles.listHeading}>{renderCategoryLink(p as FooterLinksCategory)}</li>
                {footerLinks
                  .filter((c) => c?.parent?.title === p.title)
                  .map((c) => c?.linkField?.[0] && <li key={c.title}>{renderCategoryLink(c as FooterLinksCategory)}</li>)}
              </ul>
            ),
        )}
        <ul>
          <li>
            <span>Legal</span>
          </li>
          <li>
            <a className={styles.child} href="/privacy">
              Privacy
            </a>
          </li>
          <li>
            <a className={styles.child} href="/disclaimer">
              Disclaimer
            </a>
          </li>
          <li>
            <a className={styles.child} href="/terms-conditions">
              Terms & Conditions
            </a>
          </li>
        </ul>
      </div>
    );
  }

  return null;
};

export default FooterMenu;
