import React from 'react';

import MainMenu from '../MainMenu';
import SearchButton from '../SearchButton';

import styles from './styles.module.scss';

interface Props {
  isJobPage: boolean;
  searchClick: () => void;
}

const DesktopSubMenu = ({ isJobPage, searchClick }: Props) => {
    if (isJobPage) {
        return null;
    }

    return <div className={styles.desktopMenu}>
        <nav className={styles.mainLink} aria-label="Main Menu">
            <div className={styles.hiddenElement}>
                <SearchButton onClick={searchClick}/>
            </div>
            <MainMenu menuPosition="desktopMenu"/>
        </nav>
    </div>;
};

export default DesktopSubMenu;
