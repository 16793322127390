import React from 'react';

import { Button } from '../../../index';

import SearchButton from '../SearchButton';

import styles from './styles.module.scss';
import cc from "classcat";
import IconPhone from "@/images/icons/new-icon-phone.svg";
import { useStore } from '../../../../hooks';

interface Props {
  isJobPage: boolean;
  searchClick: () => void;
}



const DesktopSkinnyMenu = ({ isJobPage, searchClick }: Props) => {
  const globals = useStore('globals');
  const phone = globals?.phone || null;

  return (
      <div className={styles.skinnyMenu}>
        <nav className={styles.skinnyMenuNav} aria-label="Skinny Menu">
          {!isJobPage && <SearchButton skinny onClick={searchClick} />}
          <div>
            {phone && (
                <a href={`tel:${phone.replace(/[^\d\+]/g, '')}`} className={styles.contactButton}>
                  {phone}
                  <IconPhone />
                </a>
            )}
            <Button
                href="/contact-us"
                className={cc([
                  styles.contact,
                  'newStyleButtonOverrideSolid',
                  'newStyleButtonOverrideWhite'
                ])}
            >
              Contact us
            </Button>
          </div>
        </nav>
      </div>
  );
};

export default DesktopSkinnyMenu;